import React from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";

function OverLimitNotFound(props) {
  props.logger.info("OverLimitNotFound", "アクセス可能期限超過");
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            エラー
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>利用可能時間を超えています。</Typography>
      </div>
    </React.Fragment>
  );
}

export default OverLimitNotFound;
