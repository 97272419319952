import React from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";

import { useLocation } from "react-router-dom";

function NotFound(props) {
  const location = useLocation();
  props.logger.info("NotFound", location.pathname);

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            エラー
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>お探しのページは見つかりませんでした。</Typography>
      </div>
    </React.Fragment>
  );
}

export default NotFound;
