import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

function errorPage(props) {
  props.logger.info("errorPage", "エラーページ表示");

  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            エラー
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>お探しのページは見つかりませんでした。(ERR001)</Typography>
      </div>
    </React.Fragment>
  );
}

export default errorPage;
