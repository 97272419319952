import "./App.css";
import { Amplify, Logger, AWSCloudWatchProvider } from "aws-amplify";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Typography } from "@mui/material";

import Upload from "./pages/Upload";
import Voice from "./pages/Voice";
import Thanks from "./pages/Thanks";

import NotFound from "./pages/NotFound";
import OverLimitNotFound from "./pages/OverLimitNotFound";
import Error from "./pages/Error";

import awsExports from "./aws-exports";

//UUID
import { v4 as uuidv4 } from "uuid";
import { logLevel, getLogging } from "./LogConst";

Amplify.configure({
  Logging: getLogging(uuidv4()),
  ...awsExports,
});
//ログ
const logger = new Logger("LOG", logLevel);
Amplify.register(logger);
logger.addPluggable(new AWSCloudWatchProvider());

function App() {
  const build_date = `${process.env.REACT_APP_BUILD_DATE}`;
  logger.info("userAgent", window.navigator.userAgent);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/upload"} element={<Upload logger={logger} />} />
        <Route path={"/voice"} element={<Voice logger={logger} />} />
        <Route path={"/thanks"} element={<Thanks logger={logger} />} />
        <Route
          path={"/overLimit"}
          element={<OverLimitNotFound logger={logger} />}
        />
        <Route path={"/error"} element={<Error logger={logger} />} />
        <Route path={"/*"} element={<NotFound logger={logger} />} />
      </Routes>
      <Typography
        align="center"
        variant="body2"
        sx={{ mt: "30px", mb: "20px" }}
      >
        北海道電力ネットワーク株式会社All Rights Reserved. Copyright © Hokkaido
        Electric Power Network, Inc.
        <br />
        {build_date}
      </Typography>
    </BrowserRouter>
  );
}

export default App;
