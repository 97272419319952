import React from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";

function Thanks(props) {
  props.logger.info("Thanks", "アップロード完了");
  return (
    <React.Fragment>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            アップロード完了
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>ご協力ありがとうございました。</Typography>
      </div>
    </React.Fragment>
  );
}

export default Thanks;
