import React, { useState, useEffect } from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Backdrop,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import { API, Storage } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import { listVoices } from "../graphql/queries";
import { createVoicePhoto } from "../graphql/mutations";

import "@aws-amplify/ui-react/styles.css";

import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";

import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

function Upload(props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [progress, setProgress] = useState(true);

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  //最大枚数
  const maxFileCnt = 5;
  //最大ファイルサイズ
  //const maxFileSize = 1024 * 1024 * 3;

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/*": [],
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (files.length + acceptedFiles.length > maxFileCnt) {
        setErrorMessage(
          "アップロード可能な写真枚数の上限を超えます。最大枚数＝" +
            maxFileCnt +
            "枚"
        );
        setErrorOpen(true);
        return false;
      }
      /* ファイルサイズ上限を設けない
      for (var i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i].size > maxFileSize) {
          setErrorMessage(
            "アップロード可能なファイルサイズを超えます。アップロード可能な写真のサイズ=" +
              maxFileSize +
              "バイト,選択した写真のサイズ=" +
              acceptedFiles[i].size +
              "バイト"
          );
          setErrorOpen(true);
          return false;
        }
      }
      */
      setImageFileList(acceptedFiles);
    },
  });

  //ファイル変換セット処理
  async function setImageFileList(acceptedFiles) {
    for (var i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].type === "image/heic") {
        setProgress(true);
        try {
          const resultBlob = await heic2any({
            blob: acceptedFiles[i],
            toType: "image/jpeg",
            quality: 1,
          });
          const file_name = acceptedFiles[i].name;
          acceptedFiles[i] = resultBlob;
          Object.assign(acceptedFiles[i], { name: file_name });
        } catch (error) {
          console.log(error);
          props.logger.error("HEICファイル変換ERROR", error);
        }
        setProgress(false);
      }
    }
    setFiles(
      files.concat(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            s3_key: voiceCode + "/" + uuidv4(),
          })
        )
      )
    );
  }

  //アップロードファイル
  const [files, setFiles] = useState([]);

  //サムネ表示
  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const [voiceCode, setVoiceCode] = useState("");
  useEffect(() => {
    const init = async (code) => {
      console.log(code);
      props.logger.info("Upload表示", code);

      try {
        const voice = await API.graphql({
          query: listVoices,
          variables: {
            id: code,
            filter: { alive_tmstmp: { gt: dayjs().format() } },
            //limit: 10,
          },
          authMode: "AWS_IAM",
          //authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        console.log(voice);
        setProgress(false);
        if (voice && voice.data && voice.data.listVoices.items) {
          if (voice.data.listVoices.items.length === 0) {
            navigate("/overLimit");
          }
        }
      } catch (error) {
        console.error(error);
        navigate("/error");
      }
    };

    const code = searchParams.get("code");
    if (code) {
      setVoiceCode(code);
      searchParams.delete("code");
      setSearchParams(searchParams);
    }
    init(code);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //クリア処理
  const clearFiles = function () {
    setFiles([]);
  };
  //ファイルアップロード
  const confirmUpload = function () {
    setDialogTitle("確認");
    setDialogMessage("写真のアップロードを行いますか？");
    setConfirmOpen(true);
  };

  //エラーアラート
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  function errorHandleClose(e) {
    setErrorOpen(false);
  }

  //確認ダイアログ
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleClose = (e) => {
    setConfirmOpen(false);
  };
  //確認OKボタン処理
  async function confirmOkAction() {
    setConfirmOpen(false);
    setProgress(true);
    try {
      await uploadFiles();
      await storeData();
      setProgress(false);
      navigate("/thanks");
    } catch (e) {
      console.log(e);
      props.logger.error("confirmOkAction", e);
      setProgress(false);
      setErrorMessage("アップロードに失敗しました。");
      setErrorOpen(true);
    }
  }
  //ファイルＳ３格納
  async function uploadFiles() {
    for (let i = 0; i < files.length; i++) {
      try {
        const result = await Storage.put(files[i].s3_key, files[i], {
          level: "public",
          contentType: files[i].type,
          metadata: {
            type: files[i].type,
            thumbnail: "true",
          },
        });
        //console.log(result);
        props.logger.info("uploadFiles", result);
      } catch (e) {
        //console.log(e);
        props.logger.error("uploadFiles", e);
        throw e;
      }
    }
  }
  //ファイル情報格納
  async function storeData() {
    for (let i = 0; i < files.length; i++) {
      let fileinfo = {
        file_name: files[i].name,
        contentType: files[i].type,
      };

      let pushData = {
        id: voiceCode,
        s3_path: files[i].s3_key,
        file_info: JSON.stringify(fileinfo),
        tmstmp: dayjs().format(),
      };
      //console.log(pushData);

      try {
        let ret = await API.graphql(
          graphqlOperation(createVoicePhoto, { input: pushData })
        );
        //console.log(ret);
        props.logger.info("storeData", ret);
      } catch (e) {
        //console.log(e);
        props.logger.error("storeData", e);
        throw e;
      }
    }
  }
  return (
    <React.Fragment>
      <Backdrop open={progress}>
        <CircularProgress color="secondary" size="8rem" />
      </Backdrop>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            ほくでんネットワーク
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "60px", marginLeft: "20px" }}>
        <Typography>投稿する写真を選択してください。</Typography>
        <div className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Button variant="contained" onClick={open}>
              選択
            </Button>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
          <div>
            <Button
              variant="contained"
              disabled={files.length === 0}
              onClick={confirmUpload}
            >
              アップロード
            </Button>
            <Button
              variant="contained"
              color="warning"
              sx={{ ml: 2 }}
              onClick={clearFiles}
              disabled={files.length === 0}
            >
              クリア
            </Button>
          </div>
          <Typography>
            ※写真は5枚までアップロードできます。
            <br />
            ※動画はアップロードできません。
            <br />
            ※ご不明な点がございましたら、お近くのほくでんネットワークにお問い合わせください。
            <br />
            <a
              href="https://www.hepco.co.jp/network/corporate/company/branch/search/index.html"
              target={"_blank"}
              rel="noreferrer"
            >
              https://www.hepco.co.jp/network
              <br />
              /corporate/company/branch
              <br />
              /search/index.html
            </a>
          </Typography>
        </div>
        {/*確認ダイアログ*/}
        <div>
          <Dialog
            open={confirmOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMessage}
                <br />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button id="btn_cancel" onClick={handleClose}>
                キャンセル
              </Button>
              <Button id="btn_ok" onClick={confirmOkAction} autoFocus>
                ＯＫ
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/*エラー表示*/}
        <div>
          <Snackbar
            open={errorOpen}
            autoHideDuration={5000}
            onClose={errorHandleClose}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
          >
            <Alert
              onClose={errorHandleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Upload;
