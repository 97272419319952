/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVoicePhoto = /* GraphQL */ `
  query GetVoicePhoto($id: ID!, $s3_path: String!) {
    getVoicePhoto(id: $id, s3_path: $s3_path) {
      id
      tmstmp
      s3_path
      file_info
      createdAt
      updatedAt
    }
  }
`;
export const listVoicePhotos = /* GraphQL */ `
  query ListVoicePhotos(
    $id: ID
    $s3_path: ModelStringKeyConditionInput
    $filter: ModelVoicePhotoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoicePhotos(
      id: $id
      s3_path: $s3_path
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tmstmp
        s3_path
        file_info
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoiceStatus = /* GraphQL */ `
  query GetVoiceStatus($id: ID!) {
    getVoiceStatus(id: $id) {
      id
      uktk_no
      guest_name
      status
      person_name
      memo
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const listVoiceStatuses = /* GraphQL */ `
  query ListVoiceStatuses(
    $id: ID
    $filter: ModelVoiceStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoiceStatuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        uktk_no
        guest_name
        status
        person_name
        memo
        tmstmp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoiceStatusUpdate = /* GraphQL */ `
  query GetVoiceStatusUpdate($id: ID!, $tmstmp: AWSTimestamp!) {
    getVoiceStatusUpdate(id: $id, tmstmp: $tmstmp) {
      id
      tmstmp
      status
      person_name
      memo
      date_time
      createdAt
      updatedAt
    }
  }
`;
export const listVoiceStatusUpdates = /* GraphQL */ `
  query ListVoiceStatusUpdates(
    $id: ID
    $tmstmp: ModelIntKeyConditionInput
    $filter: ModelVoiceStatusUpdateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoiceStatusUpdates(
      id: $id
      tmstmp: $tmstmp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tmstmp
        status
        person_name
        memo
        date_time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVoice = /* GraphQL */ `
  query GetVoice($id: ID!, $tmstmp: AWSDateTime!) {
    getVoice(id: $id, tmstmp: $tmstmp) {
      id
      tmstmp
      alive_tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const listVoices = /* GraphQL */ `
  query ListVoices(
    $id: ID
    $tmstmp: ModelStringKeyConditionInput
    $filter: ModelVoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVoices(
      id: $id
      tmstmp: $tmstmp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        tmstmp
        alive_tmstmp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
