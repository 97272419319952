/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVoicePhoto = /* GraphQL */ `
  mutation CreateVoicePhoto(
    $input: CreateVoicePhotoInput!
    $condition: ModelVoicePhotoConditionInput
  ) {
    createVoicePhoto(input: $input, condition: $condition) {
      id
      tmstmp
      s3_path
      file_info
      createdAt
      updatedAt
    }
  }
`;
export const updateVoicePhoto = /* GraphQL */ `
  mutation UpdateVoicePhoto(
    $input: UpdateVoicePhotoInput!
    $condition: ModelVoicePhotoConditionInput
  ) {
    updateVoicePhoto(input: $input, condition: $condition) {
      id
      tmstmp
      s3_path
      file_info
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoicePhoto = /* GraphQL */ `
  mutation DeleteVoicePhoto(
    $input: DeleteVoicePhotoInput!
    $condition: ModelVoicePhotoConditionInput
  ) {
    deleteVoicePhoto(input: $input, condition: $condition) {
      id
      tmstmp
      s3_path
      file_info
      createdAt
      updatedAt
    }
  }
`;
export const createVoiceStatus = /* GraphQL */ `
  mutation CreateVoiceStatus(
    $input: CreateVoiceStatusInput!
    $condition: ModelVoiceStatusConditionInput
  ) {
    createVoiceStatus(input: $input, condition: $condition) {
      id
      uktk_no
      guest_name
      status
      person_name
      memo
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const updateVoiceStatus = /* GraphQL */ `
  mutation UpdateVoiceStatus(
    $input: UpdateVoiceStatusInput!
    $condition: ModelVoiceStatusConditionInput
  ) {
    updateVoiceStatus(input: $input, condition: $condition) {
      id
      uktk_no
      guest_name
      status
      person_name
      memo
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoiceStatus = /* GraphQL */ `
  mutation DeleteVoiceStatus(
    $input: DeleteVoiceStatusInput!
    $condition: ModelVoiceStatusConditionInput
  ) {
    deleteVoiceStatus(input: $input, condition: $condition) {
      id
      uktk_no
      guest_name
      status
      person_name
      memo
      tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const createVoiceStatusUpdate = /* GraphQL */ `
  mutation CreateVoiceStatusUpdate(
    $input: CreateVoiceStatusUpdateInput!
    $condition: ModelVoiceStatusUpdateConditionInput
  ) {
    createVoiceStatusUpdate(input: $input, condition: $condition) {
      id
      tmstmp
      status
      person_name
      memo
      date_time
      createdAt
      updatedAt
    }
  }
`;
export const updateVoiceStatusUpdate = /* GraphQL */ `
  mutation UpdateVoiceStatusUpdate(
    $input: UpdateVoiceStatusUpdateInput!
    $condition: ModelVoiceStatusUpdateConditionInput
  ) {
    updateVoiceStatusUpdate(input: $input, condition: $condition) {
      id
      tmstmp
      status
      person_name
      memo
      date_time
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoiceStatusUpdate = /* GraphQL */ `
  mutation DeleteVoiceStatusUpdate(
    $input: DeleteVoiceStatusUpdateInput!
    $condition: ModelVoiceStatusUpdateConditionInput
  ) {
    deleteVoiceStatusUpdate(input: $input, condition: $condition) {
      id
      tmstmp
      status
      person_name
      memo
      date_time
      createdAt
      updatedAt
    }
  }
`;
export const createVoice = /* GraphQL */ `
  mutation CreateVoice(
    $input: CreateVoiceInput!
    $condition: ModelVoiceConditionInput
  ) {
    createVoice(input: $input, condition: $condition) {
      id
      tmstmp
      alive_tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const updateVoice = /* GraphQL */ `
  mutation UpdateVoice(
    $input: UpdateVoiceInput!
    $condition: ModelVoiceConditionInput
  ) {
    updateVoice(input: $input, condition: $condition) {
      id
      tmstmp
      alive_tmstmp
      createdAt
      updatedAt
    }
  }
`;
export const deleteVoice = /* GraphQL */ `
  mutation DeleteVoice(
    $input: DeleteVoiceInput!
    $condition: ModelVoiceConditionInput
  ) {
    deleteVoice(input: $input, condition: $condition) {
      id
      tmstmp
      alive_tmstmp
      createdAt
      updatedAt
    }
  }
`;
