import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  CircularProgress,
  Backdrop,
  Typography,
  AppBar,
  Toolbar,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";

import { API, Storage } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import { getVoiceStatus } from "../graphql/queries";
import { updateVoiceStatus } from "../graphql/mutations";
import { createVoiceStatusUpdate } from "../graphql/mutations";

import { Logout } from "@mui/icons-material";
import { SignInFooter } from "./SignInFooter";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import dayjs from "dayjs";

I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage("ja");

function Voice(props) {
  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [progress, setProgress] = useState(true);
  const [voiceCode, setVoiceCode] = useState("");
  const [guestName, setGuestName] = useState("");
  const [uktkNo, setUktkNo] = useState("");
  const [voiceStatus, setVoiceStatus] = useState("");
  const [memo, setMemo] = useState("");
  const [personName, setPersonName] = useState("");

  const [photoList, setPhotoList] = useState([]);

  async function logoout() {
    await props.signOut();
  }

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) {
      setVoiceCode(code);
    }
    props.logger.info("Teams連系Init", code);
    init(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //初期処理
  async function init(code) {
    try {
      const voice = await API.graphql({
        query: getVoiceStatus,
        variables: {
          id: code,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      //console.log(voice);
      var voiceStatus = voice.data.getVoiceStatus;
      setGuestName(voiceStatus.guest_name);
      setUktkNo(voiceStatus.uktk_no);
      setVoiceStatus(voiceStatus.status);
      setMemo(voiceStatus.memo);
      setPersonName(voiceStatus.person_name);

      const s3List = await Storage.list(code + "/", { level: "public" });
      let s3Files = [];
      for (let i = 0; i < s3List.results.length; i++) {
        const signedURL = await Storage.get(s3List.results[i].key);
        s3Files.push(
          Object.assign({ signedURL: signedURL }, s3List.results[i])
        );
      }

      setPhotoList(s3Files);

      setProgress(false);
    } catch (error) {
      //console.error(error);
      props.logger.error("Teams連系データ取得エラー", error);
      navigate("/error");
    }
  }
  //サムネ表示
  //console.log(photoList);
  //console.log(photoList.length);
  const thumbs = photoList
    .filter((file) => {
      return file.key.indexOf("-thumbnail") > 0;
    })
    .map((file) => (
      <div style={thumb} key={file.key}>
        <div style={thumbInner}>
          <img
            src={file.signedURL}
            style={img}
            alt={file.key}
            name={file.key}
            onClick={openOriginalImage}
          />
        </div>
      </div>
    ));
  //オリジナル画像を表示
  function openOriginalImage(event) {
    try {
      const orgFileKey = event.target.name.substring(
        0,
        event.target.name.indexOf("-thumbnail")
      );
      const orgS3File = photoList.find((file) => {
        return file.key === orgFileKey;
      });
      window.open(orgS3File.signedURL);
    } catch (error) {
      alert(error);
    }
  }
  //作業ステータス更新
  async function updateStatus(e) {
    //console.log(e.currentTarget.getAttribute("data-status"));
    const status = e.currentTarget.getAttribute("data-status");
    setProgress(true);
    try {
      const updVoice = {
        id: voiceCode,
        person_name: personName,
        status: status,
        memo: memo,
        tmstmp: dayjs().format(),
      };
      //console.log(updVoice);

      let ret = await API.graphql({
        ...graphqlOperation(updateVoiceStatus, { input: updVoice }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      //console.log(ret);
      props.logger.info("updateStatus", ret);

      const updVoiceStaus = {
        id: voiceCode,
        person_name: personName,
        status: status,
        memo: memo,
        tmstmp: dayjs().valueOf(),
        date_time: dayjs().format(),
      };
      //console.log(updVoiceStaus);
      ret = await API.graphql({
        ...graphqlOperation(createVoiceStatusUpdate, { input: updVoiceStaus }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      //console.log(ret);
      props.logger.info("statusQueue", ret);

      setVoiceStatus(status);
      setMsgOpen(true);
    } catch (error) {
      console.error(error);
      props.logger.error("Teams連系ステータス更新エラー", error);
      alert(error);
    }
    setProgress(false);
  }
  const [msgOpen, setMsgOpen] = useState(false);
  function handleClose() {
    setMsgOpen(false);
  }
  return (
    <React.Fragment>
      <Backdrop open={progress}>
        <CircularProgress color="secondary" size="8rem" />
      </Backdrop>
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            お客さまサポート連系
          </Typography>
        </Toolbar>
      </AppBar>
      <div
        style={{ marginTop: "60px", marginLeft: "20px", marginRight: "20px" }}
      >
        <Typography>お客さま名：{guestName}</Typography>
        <br />
        <Typography>受付番号：{uktkNo}</Typography>
        <br />
        <Typography>作業状況：{voiceStatus}</Typography>
        <br />
        <div>
          <TextField
            fullWidth
            type="text"
            size="small"
            label="担当者"
            variant="outlined"
            id="person_name"
            value={personName ?? ""}
            onChange={(e) => {
              setPersonName(e.target.value);
            }}
          ></TextField>
        </div>
        <br />
        <div>
          <TextField
            fullWidth
            multiline
            rows={3}
            type="text"
            size="small"
            label="特記事項"
            placeholder="特記事項があれば入力してください。"
            variant="outlined"
            id="memo"
            value={memo ?? ""}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
          ></TextField>
        </div>
        <br />
        <div>
          <Button
            variant="contained"
            onClick={updateStatus}
            data-status="作業中"
          >
            作業中
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ ml: 2 }}
            onClick={updateStatus}
            data-status="完了"
          >
            完　了
          </Button>
        </div>
        <aside>
          <h4>アップロード画像</h4>
          {thumbs}
        </aside>
      </div>
      <div>
        <Button
          variant="outlined"
          size="large"
          endIcon={<Logout />}
          id="btn_logout"
          onClick={logoout}
          sx={{ ml: 2, mt: 5 }}
        >
          ログアウト
        </Button>
      </div>

      <Snackbar open={msgOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          作業ステータスを更新しました。
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
export default withAuthenticator(Voice, {
  hideSignUp: true,
  components: { SignIn: { Footer: SignInFooter } },
});
